"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.basicPermissions = exports.grantPermissions = exports.Permissions = void 0;
var Permissions;
exports.Permissions = Permissions;
(function(Permissions) {
    Permissions[/**
   * ORGANIZATIONS - BASICS
   */ "CREATE_ORGANIZATION"] = "CREATE_ORGANIZATION";
    Permissions["UPDATE_ORGANIZATION"] = "UPDATE_ORGANIZATION";
    Permissions["GRANT_ADMIN_PERMISSION_ORGANIZATION"] = 'GRANT_ADMIN_PERMISSION_DOCUMENT';
    Permissions[/**
   * DOCUMENTS - BASICS
   */ "SEARCH_PUBLIC_DOCUMENT"] = "SEARCH_PUBLIC_DOCUMENT";
    Permissions["DOWNLOAD_SRC_DOCUMENT"] = "DOWNLOAD_SRC_DOCUMENT";
    Permissions[/**
   * DOCUMENTS - ADMIN
   */ "SEARCH_ALL_DOCUMENTS"] = "SEARCH_ALL_DOCUMENTS";
    Permissions["MAKE_FEATURED_DOCUMENT"] = "MAKE_FEATURED_DOCUMENT";
    Permissions["CREATE_DOCUMENT"] = "CREATE_DOCUMENT";
    Permissions["UPDATE_DOCUMENT"] = "UPDATE_DOCUMENT";
    Permissions["ARCHIVE_DOCUMENT"] = "ARCHIVE_DOCUMENT";
    Permissions["PUBLISH_DOCUMENT"] = "PUBLISH_DOCUMENT";
    Permissions["DELETE_DOCUMENT"] = "DELETE_DOCUMENT";
    Permissions["UPDATE_DOCUMENT_OWNER"] = "UPDATE_DOCUMENT_OWNER";
    Permissions["GRANT_ADMIN_PERMISSION_DOCUMENT"] = "GRANT_ADMIN_PERMISSION_DOCUMENT";
    Permissions[/**
   * ELEARNING - BASICS
   */ "SEARCH_PUBLIC_ELEARNING"] = "SEARCH_PUBLIC_ELEARNING";
    Permissions["ATTEND_ELEARNING"] = "ATTEND_ELEARNING";
    Permissions[/**
   * ELEARNING - ADMIN
   * Quizzes and e-learning sections inherit routes inherit from CREATE_ELEARNING and UPDATE_ELEARNING
   */ "SEARCH_ALL_ELEARNING"] = "SEARCH_ALL_ELEARNING";
    Permissions["CREATE_ELEARNING"] = "CREATE_ELEARNING";
    Permissions["UPDATE_ELEARNING"] = "UPDATE_ELEARNING";
    Permissions["ARCHIVE_ELEARNING"] = "ARCHIVE_ELEARNING";
    Permissions["PUBLISH_ELEARNING"] = "PUBLISH_ELEARNING";
    Permissions["DELETE_ELEARNING"] = "DELETE_ELEARNING";
    Permissions["GRANT_ADMIN_PERMISSION_ELEARNING"] = "GRANT_ADMIN_PERMISSION_ELEARNING";
    Permissions[/**
   * COURSES - BASICS
   */ "SEARCH_PUBLIC_COURSES"] = "SEARCH_PUBLIC_COURSES";
    Permissions["ATTEND_COURSES"] = "ATTEND_COURSES";
    Permissions[/**
   * COURSES - ADMIN
   * Quizzes and courses sections inherit routes inherit from CREATE_COURSES and UPDATE_COURSES
   */ "SEARCH_ALL_COURSES"] = "SEARCH_ALL_COURSES";
    Permissions["CREATE_COURSES"] = "CREATE_COURSES";
    Permissions["UPDATE_COURSES"] = "UPDATE_COURSES";
    Permissions["ARCHIVE_COURSES"] = "ARCHIVE_COURSES";
    Permissions["PUBLISH_COURSES"] = "PUBLISH_COURSES";
    Permissions["DELETE_COURSES"] = "DELETE_COURSES";
    Permissions["UPDATE_COURSE_OWNER"] = "UPDATE_COURSE_OWNER";
    Permissions["GRANT_ADMIN_PERMISSION_COURSES"] = "GRANT_ADMIN_PERMISSION_COURSES";
    Permissions[/**
   * FORUM - BASICS
   */ "SEARCH_PUBLIC_DISCUSSION"] = "SEARCH_PUBLIC_DISCUSSION";
    Permissions["CREATE_DISCUSSION_MESSAGE"] = "CREATE_DISCUSSION_MESSAGE";
    Permissions["UPDATE_DISCUSSION_MESSAGE"] = "UPDATE_DISCUSSION_MESSAGE";
    Permissions[/**
   * FORUM - ADMIN
   */ "SEARCH_ALL_DISCUSSION"] = "SEARCH_ALL_DISCUSSION";
    Permissions["CREATE_DISCUSSION"] = "CREATE_DISCUSSION";
    Permissions["UPDATE_DISCUSSION"] = "UPDATE_DISCUSSION";
    Permissions["MODERATE_DISCUSSION"] = "MODERATE_DISCUSSION";
    Permissions["DELETE_DISCUSSION"] = "DELETE_DISCUSSION";
    Permissions["GRANT_ADMIN_PERMISSION_DISCUSSION"] = "GRANT_ADMIN_PERMISSION_DISCUSSION";
    Permissions[/**
   * USERS - ADMIN
   */ "SEARCH_ALL_USER"] = "SEARCH_ALL_USER";
    Permissions["INVITE_USER"] = "INVITE_USER";
    Permissions["BAN_USER"] = "BAN_USER";
    Permissions["DELETE_USER"] = "DELETE_USER";
    Permissions["UPDATE_USER_LABELS"] = "UPDATE_USER_LABELS";
    Permissions["GRANT_ADMIN_PERMISSION_USER"] = "GRANT_ADMIN_PERMISSION_USER";
    Permissions[/**
   * ADMINISTRATION
   */ "SEE_HISTORY_ADMINISTRATION"] = "SEE_HISTORY_ADMINISTRATION";
    Permissions["SEE_ANALYTICS_ADMINISTRATION"] = "SEE_ANALYTICS_ADMINISTRATION";
    Permissions["SEND_CUSTOM_NOTIFICATION_ADMINISTRATION"] = "SEND_CUSTOM_NOTIFICATION_ADMINISTRATION";
    Permissions["MANAGE_REPORTS_ADMINISTRATION"] = "MANAGE_REPORTS_ADMINISTRATION";
    Permissions["GRANT_ADMIN_PERMISSION_ADMINISTRATION"] = "GRANT_ADMIN_PERMISSION_ADMINISTRATION";
    Permissions["OWNER"] = "OWNER";
})(Permissions || (exports.Permissions = Permissions = {}));
const grantPermissions = [
    Permissions.GRANT_ADMIN_PERMISSION_DISCUSSION,
    Permissions.GRANT_ADMIN_PERMISSION_DOCUMENT,
    Permissions.GRANT_ADMIN_PERMISSION_ELEARNING,
    Permissions.GRANT_ADMIN_PERMISSION_USER,
    Permissions.GRANT_ADMIN_PERMISSION_COURSES,
    Permissions.GRANT_ADMIN_PERMISSION_ADMINISTRATION,
    Permissions.GRANT_ADMIN_PERMISSION_ORGANIZATION, 
];
exports.grantPermissions = grantPermissions;
const basicPermissions = [
    // SEARCH AND READ
    Permissions.SEARCH_PUBLIC_DOCUMENT,
    Permissions.SEARCH_PUBLIC_ELEARNING,
    Permissions.SEARCH_PUBLIC_COURSES,
    Permissions.SEARCH_PUBLIC_DISCUSSION,
    Permissions.DOWNLOAD_SRC_DOCUMENT,
    Permissions.ATTEND_ELEARNING,
    Permissions.ATTEND_COURSES,
    Permissions.CREATE_DISCUSSION_MESSAGE,
    Permissions.UPDATE_DISCUSSION_MESSAGE, 
];
exports.basicPermissions = basicPermissions;

import { createSlice } from '@reduxjs/toolkit';

import { AdminReducer } from './Admin.model';

const initialState: AdminReducer = {
  allUsers: null,
  pendingUsers: null,
};

export const adminSlice = createSlice({
  name: 'adminReducer',
  initialState,
  reducers: {
    SET_ALL_USERS(state, action) {
      return {
        ...state,
        allUsers: action.payload,
      };
    },
    SET_PENDING_USERS(state, action) {
      return {
        ...state,
        pendingUsers: action.payload,
      };
    },
  },
});

export const { SET_ALL_USERS, SET_PENDING_USERS } = adminSlice.actions;

export const adminReducer = adminSlice.reducer;
